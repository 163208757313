<template>
    <CardComponent
        :title="title"
        :icon="icon"
        link=""
        class="value-percent"
    >
        <section v-loading="loading">
            <gb-echart
                v-if="chartData.length > 0"
                ref="valuePercent"
                config="goods-value-percent"
                height="100%"
                width="100%"
            />
            <Empty v-else />
        </section>
    </CardComponent>
</template>

<script>
import CardComponent from '../components/card'
import GbEchart from '@/components/Echart'
import tradeSvc from 'services/trade'
import Empty from '@/components/empty'
export default {
    name: 'PurchaseRange',
    components: { CardComponent, GbEchart, Empty },
    data () {
        return {
            title: '货值占比',
            icon: 'iconfont icon-zhuzhuangtu',
            chartData: [],
            loading: false
        }
    },
    created () {
        // 货值占比
        this.getStockRate()
    },
    methods: {
        // 货值占比
        async getStockRate () {
            try {
                this.loading = true
                const { data = [] } = await tradeSvc.getStockRate()
                // const data = [
                //     {
                //         brandName: '132',
                //         authenticQuantity: 22222,
                //         defectiveQuantity: 123
                //     },
                //     {
                //         brandName: '方法',
                //         authenticQuantity: 22222,
                //         defectiveQuantity: 123
                //     },
                //     {
                //         brandName: '的基督教',
                //         authenticQuantity: 9999999,
                //         defectiveQuantity: 9999999
                //     },
                //     {
                //         brandName: '其他',
                //         authenticQuantity: 1,
                //         defectiveQuantity: 222
                //     },
                //     {
                //         brandName: '其他',
                //         authenticQuantity: 2,
                //         defectiveQuantity: 0
                //     }
                // ]
                this.loading = false
                data.forEach(item => {
                    if (item.authenticQuantity !== null) {
                        item.authenticQuantity = (
                            item.authenticQuantity / 10000
                        ).toFixed(2)
                    }
                    if (item.defectiveQuantity !== null) {
                        item.defectiveQuantity = (
                            item.defectiveQuantity / 10000
                        ).toFixed(2)
                    }
                })
                this.chartData = data
                if (this.chartData.length > 0) {
                    let yAxisData = []
                    let data1 = []
                    let data2 = []
                    let labels = []
                    data.forEach(item => {
                        yAxisData.push(item.brandName)
                        // 残次
                        data1.push(item.defectiveQuantity)
                        // 正品
                        data2.push(item.authenticQuantity)
                        labels.push(
                            `${item.authenticQuantity}/${item.defectiveQuantity}万`
                        )
                    })
                    this.$nextTick(() => {
                        const echart = this.$refs.valuePercent
                        if (echart.option) {
                            echart.option.yAxis.data = yAxisData.reverse()
                            echart.option.labels = labels.reverse()
                            echart.option.series[0].data = data2.reverse()
                            echart.option.series[1].data = data1.reverse()
                            echart.refresh()
                        }
                    })
                }
                // yAxis.data = data
            } catch (error) {
                console.log(error)
                this.loading = false
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.value-percent {
    grid-row-start: span 2;
    grid-column-start: span 2;
}
</style>
